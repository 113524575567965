<template>
	<v-lazy :min-height="200" :options="{ threshold: 0.5 }" transition="fade-transition">
		<v-col cols="12" xs="12" md="12" lg="12">
			<div data-v-b1cf112f="" id="TA_selfserveprop831" class="TA_selfserveprop" style="display: block">
				<div id="CDSWIDSSP" class="widSSP widSSP" style="width: 100%">
					<a class="widSSPClickWrap" target="_blank" aria-label="TripAdvisor Listing"
						href="https://www.tripadvisor.com.ph/Attraction_Review-g294257-d14210359-Reviews-Diskubre_Palawan-Puerto_Princesa_Palawan_Island_Palawan_Province_Mimaropa.html"
						onclick="ta.cds.handleTALink(11900,this);return true;"></a>
					<div class="widSSPData withBorder cx_brand_refresh">
						<div class="widSSPBranding">
							<a target="_blank" aria-label="TripAdvisor" href="https://www.tripadvisor.com.ph/"><nuxt-img
									loading="lazy" height="30" src="/images/ta.svg" alt="TripAdvisor"
									class="logo" /></a>
						</div>
						<!--/ cdsBranding-->
						<div class="widSSPComponent">
							<div class="widSSPSummary">
								<dl>
									<a target="_blank" aria-label="TripAdvisor Listing"
										href="https://www.tripadvisor.com.ph/Attraction_Review-g294257-d14210359-Reviews-Diskubre_Palawan-Puerto_Princesa_Palawan_Island_Palawan_Province_Mimaropa.html"
										onclick="ta.cds.handleTALink(11900,this);return true;" rel="nofollow">
										<span class="widSSPH18">Diskubre Palawan</span>
									</a>
								</dl>
							</div>
							<!--/ cdsSummary-->
						</div>
						<!--/ cdsComponent-->
						<div class="widSSPComponent widSSPOptional">
							<div class="widSSPTrvlRtng">
								<dl>
									<dt class="widSSPH11">Tripadvisor Traveler Rating</dt>
									<dd>
										<div class="widSSPOverall"><span class="ui_bubble_rating bubble_50"></span>
											<span>489 reviews</span>
										</div>
										<!--/ overall -->
									</dd>
								</dl>
							</div>
						</div>
						<!--/ cdsComponent -->
						<div class="widSSPWrap widSSPOptional">
							<div class="widSSPWrap">
								<dl>
									<dt class="widSSPH11">Tripadvisor Ranking</dt>
								</dl>
								<div class="widSSPPopIdx widSSPSingle">
									<span class="widSSPPopIdxData">
										<span class="widSSPPopIdxData"><strong><sup>#</sup>2 of 384
											</strong></span><strong> Travel Company in Palawan Island </strong></span>
								</div>
								<!--/ popIdx-->
							</div>
							<!--/ cdsInformation-->
						</div>
						<!--/ cdsWrap-->
						<div class="widSSPAll">
							<ul class="widSSPReadReview">
								<li>
									<a href="https://www.tripadvisor.com.ph/Attraction_Review-g294257-d14210359-Reviews-Diskubre_Palawan-Puerto_Princesa_Palawan_Island_Palawan_Province_Mimaropa.html"
										id="allreviews" aria-label="TripAdvisor Listing"
										onclick="ta.cds.handleTALink(11900,this);window.open(this.href, 'newTAWindow', 'toolbar=1,resizable=1,menubar=1,location=1,status=1,scrollbars=1,width=800,height=600'); return false"
										rel="nofollow">Read reviews</a>
								</li>
							</ul>
						</div>
						<!--/ cdsAll-->
					</div>
					<!--/ cdsData-->
				</div>
				<!--/ CDSPOP.cdsBx-->
			</div>
		</v-col>
	</v-lazy>
</template>
<script setup></script>
<style scoped>
.TA_selfserveprop {
	max-width: 300px;
	margin: 0 auto;
}

.CDSWIDSSP {
	width: 100% !important;
}

.ui_bubble_rating {
	display: inline-block;
	position: relative;
}

.ui_bubble_rating:after,
.ui_bubble_rating:before {
	display: inline-block;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	font-size: inherit;
	line-height: 1;
	font-family: TripAdvisor_Regular !important;
	-ms-transform: rotate(-0.001deg);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	speak: none;
}

.ui_bubble_rating:before {
	color: #fff;
	display: block;
	content: '\e00a\e00a\e00a\e00a\e00a';
}

.ui_bubble_rating:after {
	position: absolute;
	top: 0;
	left: 0;
	color: #00aa6c;
}

.ui_bubble_rating.bubble_5:after,
.ui_bubble_rating.bubble_50:after {
	content: '\e00b\e00b\e00b\e00b\e00b';
}

@font-face {
	font-weight: 400;
	font-style: normal;
	font-family: TripAdvisor_Regular;
	font-display: block;
	src: url(~/assets/fonts/TripAdvisor_Regular.woff2?v004.023) format('woff2');
}

#CDSWIDSSP .widSSPData.cx_brand_refresh .widSSPBranding dt img {
	width: 100px;
}

#CDSWIDSSP {
	font: normal 12px Trip Sans VF, Trip Sans, Arial, sans-serif;
	color: #333;
	overflow: hidden;
	height: auto;
	background-color: #fff;
	margin: 0;
	padding: 0;
	text-align: left;
	position: relative;
	background-color: transparent;
	letter-spacing: 0;
}

#CDSWIDSSP .logo {
	width: 100px;
	height: 22px;
}

#CDSWIDSSP .withBorder {
	border: 1px solid #34e0a1;
}

#CDSWIDSSP a {
	margin: 0;
	padding: 0;
	outline: none;
}

#CDSWIDSSP a,
#CDSWIDSSP a:hover {
	border: none;
	font-weight: 400;
	text-decoration: underline;
	color: #000;
	border-bottom: none;
}

#CDSWIDSSP a:hover {
	background-color: transparent;
}

#CDSWIDSSP a:visited {
	border: none;
	font-weight: 400;
	color: #000;
}

#CDSWIDSSP dd,
#CDSWIDSSP dl,
#CDSWIDSSP dt,
#CDSWIDSSP li,
#CDSWIDSSP ol,
#CDSWIDSSP p,
#CDSWIDSSP ul {
	margin: 0;
	padding: 0;
	list-style: none;
	font-weight: 400;
	font-style: normal;
	color: #333;
	text-indent: 0;
	float: none;
	line-height: normal;
	font-size: 100%;
	letter-spacing: 0;
	display: block;
}

#CDSWIDSSP div {
	font: normal 12px Trip Sans VF, Trip Sans, Arial, sans-serif;
}

#CDSWIDSSP img {
	border: none;
	background-color: #fff;
	padding: 0;
}

#CDSWIDSSP .widSSPData {
	padding: 7px 10px 7px;
	background-color: #fff;
	position: relative;
	overflow: hidden;
}

#CDSWIDSSP .widSSPData .widSSPWrap {
	height: auto;
	overflow: hidden;
	position: relative;
}

#CDSWIDSSP .widSSPData dl {
	padding: 0 2px;
}

#CDSWIDSSP .widSSPData dl dd dl {
	padding: 0;
}

#CDSWIDSSP .widSSPData .widSSPH18 {
	font: 700 22px Trip Sans VF, Trip Sans, Arial, sans-serif;
	color: #000;
}

#CDSWIDSSP .widSSPData .widSSPSummary a {
	color: #333;
}

#CDSWIDSSP .widSSPData .widSSPH11 {
	padding: 12px 0 3px;
	font-size: 11px;
	color: #000;
}

#CDSWIDSSP .widSSPData label {
	font-weight: 700;
	font-family: Trip Sans VF, Trip Sans, Arial, sans-serif;
}

#CDSWIDSSP .widSSPData select {
	margin: 0 1px;
	padding: 1px;
	border: 1px solid #b7cb92;
	font-size: 11px;
	font-family: Trip Sans VF, Trip Sans, Arial, sans-serif;
}

#CDSWIDSSP .widSSPData .widSSPOneReview {
	margin-top: 8px;
	padding: 20px 0 0;
	margin: 0;
	padding: 0;
	border-top: none;
}

#CDSWIDSSP .widSSPData .widSSPOneReview img {
	vertical-align: top;
}

#CDSWIDSSP .widSSPData .widSSPOneReview b.widSSPTitle {
	margin-bottom: 8px;
}

#CDSWIDSSP .widSSPData .widSSPOneReview dl dd,
#CDSWIDSSP .widSSPData .widSSPOneReview dl dt {
	margin-bottom: 9px;
}

#CDSWIDSSP .widSSPData .widSSPOneReview dl dt.widSSPQuote {
	margin-bottom: 9px;
	font-weight: 400;
}

#CDSWIDSSP .widSSPData .widSSPOneReview dl dd.widSSPAttribution {
	margin-bottom: 2px;
	font-size: 11px;
	color: #666;
}

#CDSWIDSSP .widSSPData .widSSPOneReview p {
	font-size: 12px;
	line-height: 16px;
	margin-bottom: 2px;
	font-size: 11px;
	color: #333;
}

#CDSWIDSSP .widSSPData .widSSPOneReview .widSSPBullet {
	margin: 3px 0 8px;
	border: none;
}

#CDSWIDSSP .widSSPData .widSSPOneReview .widSSPBullet li {
	letter-spacing: 0;
	font: normal 12.5px Trip Sans VF, Trip Sans, Arial, sans-serif;
	margin: 0 0 11px;
	line-height: normal;
	color: #333;
	background: none;
	border: none;
	position: relative;
}

#CDSWIDSSP .widSSPData .widSSPOneReview .widSSPBullet li a {
	color: #000;
	line-height: normal;
}

#CDSWIDSSP .widSSPData .widSSPOneReview .widSSPBullet li a,
#CDSWIDSSP .widSSPData .widSSPOneReview .widSSPBullet li a:hover {
	font: normal 12.5px Trip Sans VF, Trip Sans, Arial, sans-serif;
	text-decoration: underline;
	border: none;
}

#CDSWIDSSP .widSSPData .widSSPOneReview .widSSPBullet li span {
	font: normal 12.5px Trip Sans VF, Trip Sans, Arial, sans-serif;
	color: #333;
	display: inline;
	position: relative;
	border: none;
}

#CDSWIDSSP .widSSPData .widSSPTrvlRtng {
	height: auto;
	overflow: hidden;
	font-size: 11px;
}

#CDSWIDSSP .widSSPData .widSSPTrvlRtng .widSSPOverall {
	margin-bottom: 0;
}

#CDSWIDSSP .widSSPData .widSSPTrvlRtng .widSSPOverall img {
	margin: 3px 6px 4px 0;
	float: none;
	border: none;
}

#CDSWIDSSP .widSSPData .widSSPTrvlRtng .widSSPOverall div {
	padding-left: 2px;
	color: #b7b7b7;
	font: normal 11px Trip Sans VF, Trip Sans, Arial, sans-serif;
}

#CDSWIDSSP .widSSPData .widSSPTrvlRtng .widSSPOverall div b {
	font-weight: 400;
	color: #b7b7b7;
	font: normal 12px Trip Sans VF, Trip Sans, Arial, sans-serif;
}

#CDSWIDSSP .widSSPData .widSSPTrvlRtng .widSSPOverall em {
	position: relative;
	top: -4px;
	font-style: normal;
}

#CDSWIDSSP .widSSPData .widSSPTrvlRtng .widSSPOverall em b {
	font-size: 13px;
}

#CDSWIDSSP .widSSPData .widSSPTrvlRtng .widSSPOverall .ui_bubble_rating {
	font-size: 14px;
}

#CDSWIDSSP .widSSPData .widSSPTrvlRtng ul {
	margin-bottom: 10px;
}

#CDSWIDSSP .widSSPData .widSSPTrvlRtng ul li b {
	display: block;
	padding-bottom: 2px;
}

#CDSWIDSSP .widSSPData .widSSPTrvlRtng ul li ul li {
	margin: 8px 0;
}

#CDSWIDSSP .widSSPData .widSSPTrvlRtng ul li ul li img {
	vertical-align: text-top;
}

#CDSWIDSSP .widSSPData .widSSPReviews .widSSPH11 {
	padding-top: 12px;
}

#CDSWIDSSP .widSSPData .widSSPInformation {
	height: auto;
	overflow: hidden;
	margin-top: 2px;
	padding: 0 0 0;
}

#CDSWIDSSP .widSSPData .widSSPInformation .widSSPPopIdx {
	margin: 0 0 0 3px;
}

#CDSWIDSSP .widSSPData .widSSPInformation .widSSPPopIdx * {
	vertical-align: top;
	line-height: normal;
}

#CDSWIDSSP .widSSPData .widSSPInformation .widSSPPopIdx b {
	display: block;
	margin-bottom: 3px;
	font-size: 11px;
	color: #333;
}

#CDSWIDSSP .widSSPData .widSSPInformation .widSSPPopIdx var {
	color: #00aa6c;
	font-style: normal;
	margin-right: 4px;
	font-weight: 700;
	float: left;
}

#CDSWIDSSP .widSSPData .widSSPInformation .widSSPPopIdx var b {
	display: inline;
	line-height: 29px;
}

#CDSWIDSSP .widSSPData .widSSPInformation .widSSPPopIdx var b span {
	padding-left: 2px;
	font-family: Trip Sans VF, Trip Sans, Arial, sans-serif;
	color: #00aa6c;
}

#CDSWIDSSP .widSSPData .widSSPInformation .widSSPPopIdx span {
	color: #00aa6c;
	font-size: 11px;
}

#CDSWIDSSP .widSSPData .widSSPInformation .widSSPPopIdx span i {
	font-style: normal;
	color: #333;
}

#CDSWIDSSP .widSSPData .widSSPInformation .widSSPPopIdx var.widSSPDig1 b * {
	top: -3px;
	font-size: 29px;
}

#CDSWIDSSP .widSSPData .widSSPInformation .widSSPPopIdx var.widSSPDig2 b * {
	top: -4px;
	font-size: 24px;
}

#CDSWIDSSP .widSSPData .widSSPInformation .widSSPPopIdx var.widSSPDig3 b * {
	top: -5px;
	font-size: 20px;
}

#CDSWIDSSP .widSSPData .widSSPInformation .widSSPPopIdx var.widSSPDig4 b * {
	top: -6px;
	font-size: 16px;
}

#CDSWIDSSP .widSSPData .widSSPInformation .widSSPPopIdx .widSSPPopIdxData {
	font-family: Trip Sans VF, Trip Sans, Arial, sans-serif;
	color: #000;
	font-size: 17px;
	font-weight: 400;
}

#CDSWIDSSP .widSSPData .widSSPInformation .widSSPPopIdx.widSSPSingle {
	width: 95%;
	margin-right: 0;
}

#CDSWIDSSP .widSSPData .widSSPLegal {
	margin: 5px 2px 2px;
	font-size: 11px;
	color: #b7b7b7;
	clear: both;
}

#CDSWIDSSP .widSSPData .widSSPAll {
	height: auto;
	overflow: hidden;
	margin: 0 0 11px;
	padding: 0 0 5px 4px;
	font-size: 11px;
}

#CDSWIDSSP .widSSPData .widSSPAll ul {
	clear: none;
}
</style>
